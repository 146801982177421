@if (isDialogOpened()) {
  <p-dialog
    #dialog
    [visible]="isDialogOpened()"
    [closable]="false"
    [resizable]="true"
    appendTo="body"
    contentStyleClass="px-4"
  >
    <ng-template pTemplate="content">
      <div class="flex flex-col h-full">
        <img
          src="assets/images/externi-logo.svg"
          class="mt-6 h-8 w-auto self-start"
          alt="Logo"
        />
        <div
          class="flex flex-col gap-10 justify-center items-center px-1 pt-[6.25rem]"
        >
          <div class="flex flex-col gap-y-4 items-center">
            <h2 class="h2-mobile">Desktop Friendly</h2>
            <p class="body-1-regular-mobile text-grey-5 text-center">
              This application is optimized for <br />
              desktop. Please use a laptop or <br />
              desktop computer for the <br />
              best experience.
            </p>
          </div>
          <img
            src="/assets/images/mobile-notification.svg"
            alt="Mobile Notification"
            class="transition-all duration-150 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
          />
        </div>
        <div
          class="flex flex-col gap-3 justify-center items-center mt-auto mb-14 pt-12 pb-4"
        >
          <span class="body-1-regular text-grey-5">Open on desktop</span>
          <div
            class="text-navy bg-black-2 rounded-xl body-1-regular-mobile px-3 py-2"
          >
            app.externi.io
          </div>
        </div>
      </div>
    </ng-template>
  </p-dialog>
}
