import { Routes } from "@angular/router";
import { authorizedOnly } from "./core/auth/guards";
import { enrollmentResolver } from "./enrollment/enrollment.resolver";
import { provideStates } from "@ngxs/store";
import { Permissions } from "@platform-app/app/core/api/models/permissions";
import { agreementBuilderResolver } from "@platform-app/app/agreement-builder/agreement-builder.resolver";
import { canComponentDeactivate } from "@platform-app/app/core/guards/deactivate-component.guard";
import { ApplicationState } from "@platform-app/app/main/application/application.state";
import {
  onboardingWizardAccessibleGuard,
  onboardingWizardCompletedGuard,
} from "@platform-app/app/onboarding-wizard/shared/guards";
import { OnboardingWizardFormsState } from "@platform-app/app/onboarding-wizard/shared/onboarding-wizard-forms.state";
import { onboardingWizardResolver } from "@platform-app/app/onboarding-wizard/shared/resolver";

export const routes: Routes = [
  {
    path: "account",
    loadComponent: () =>
      import("./account/account-layout.component").then(
        (mod) => mod.AccountLayoutComponent,
      ),
    loadChildren: () =>
      import("./account/account.routes").then((mod) => mod.AccountRoutes),
  },
  {
    path: "enrollment/:id",
    resolve: { data: enrollmentResolver },
    providers: [provideStates([ApplicationState])],
    canActivate: [
      authorizedOnly([
        Permissions.SchoolApplicationsView,
        Permissions.ClinicApplicationsView,
      ]),
      onboardingWizardCompletedGuard,
    ],
    loadComponent: () =>
      import("./enrollment/enrollment-layout.component").then(
        (c) => c.EnrollmentLayoutComponent,
      ),
    loadChildren: () =>
      import("./enrollment/enrollment.routes").then((m) => m.EnrollmentRoutes),
  },
  {
    path: "agreement-builder",
    resolve: { agreementBuilderResolver },
    canActivate: [
      authorizedOnly([
        Permissions.SchoolAffiliationAgreementsEdit,
        Permissions.ClinicAffiliationAgreementsEdit,
      ]),
      onboardingWizardCompletedGuard,
    ],
    canDeactivate: [canComponentDeactivate],
    loadComponent: () =>
      import("./agreement-builder/agreement-builder-layout.component").then(
        (c) => c.AgreementBuilderLayoutComponent,
      ),
  },
  {
    path: "",
    loadComponent: () =>
      import("./main/main-layout.component").then(
        (mod) => mod.MainLayoutComponent,
      ),
    loadChildren: () =>
      import("./main/main.routes").then((mod) => mod.MainRoutes),
    canActivate: [authorizedOnly(), onboardingWizardCompletedGuard],
  },
  {
    path: "onboarding",
    resolve: { onboardingWizardResolver },
    canActivate: [
      authorizedOnly([
        Permissions.SchoolOnboardingView,
        Permissions.ClinicOnboardingView,
      ]),
      onboardingWizardAccessibleGuard,
    ],
    providers: [provideStates([OnboardingWizardFormsState])],
    loadComponent: () =>
      import("./onboarding-wizard/onboarding-wizard-layout.component").then(
        (c) => c.OnboardingWizardLayoutComponent,
      ),
    loadChildren: () =>
      import("./onboarding-wizard/shared/routes").then(
        (mod) => mod.OnboardingWizardRoutes,
      ),
  },
  {
    path: "**",
    redirectTo: "",
  },
];
