import { inject, Injectable, signal } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { RoutingUtility } from "@platform-app/app/main/shared/utilities/routing.utility";
import { filter } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScreenNotificationVisibilityService {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  private isMobileSupportedPage = signal(false);

  constructor() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const route = RoutingUtility.getDeepestRoute(this.activatedRoute);
        this.isMobileSupportedPage.set(route?.snapshot.data["mobileSupport"]);
      });
  }

  public shouldShowDialog(): boolean {
    return !this.isMobileSupportedPage();
  }
}
