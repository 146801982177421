import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule } from "@ngxs/store";
import { environment } from "../environments/environment";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { SignUpState } from "./account/sign-up/sign-up.state";
import { AuthState } from "./core/auth/auth.state";
import { importProvidersFrom } from "@angular/core";
import {
  LOCAL_STORAGE_ENGINE,
  NgxsStoragePluginModule,
  SESSION_STORAGE_ENGINE,
} from "@ngxs/storage-plugin";
import { OrganizationInfoState } from "./main/organization-settings-page/profile-settings-page/organization-info.state";
import { UIPreferencesState } from "./main/shared/state/preferences/ui-preferences.state";
import { EmailVerificationPageState } from "./account/email-verification-page/email-verification-page.state";
import { MyOpportunityState } from "./main/my-opportunities/my-opportunities.state";
import { OpportunitiesListingState } from "./main/opportunities-listing/opportunities-listing.state";
import { StaticDataState } from "./main/shared/state/static-data.state";
import { OnboardingState } from "@platform-app/app/main/dashboard-page/onboarding.state";
import { AgreementBuilderState } from "@platform-app/app/agreement-builder/agreement-builder.state";
import { DashboardStatisticsState } from "@platform-app/app/main/dashboard-page/stats-panel/state/dashboard-statistics.state";
import { OnboardingWizardState } from "@platform-app/app/onboarding-wizard/shared/onboarding-wizard.state";

const states = [
  AuthState,
  SignUpState,
  EmailVerificationPageState,
  OrganizationInfoState,
  OnboardingState,
  UIPreferencesState,
  MyOpportunityState,
  OpportunitiesListingState,
  StaticDataState,
  AgreementBuilderState,
  OnboardingWizardState,
  DashboardStatisticsState,
];

export const provideNgxs = () =>
  importProvidersFrom(
    NgxsModule.forRoot(states),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !environment.enableNgxsDebugging,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.enableNgxsDebugging,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: [
        {
          key: AuthState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: UIPreferencesState,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: StaticDataState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: EmailVerificationPageState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: MyOpportunityState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: OpportunitiesListingState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: OrganizationInfoState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: OnboardingState,
          engine: SESSION_STORAGE_ENGINE,
        },
        {
          key: DashboardStatisticsState,
          engine: SESSION_STORAGE_ENGINE,
        },
      ],
    }),
  );
