import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  OnDestroy,
  Renderer2,
  viewChild,
} from "@angular/core";
import { Dialog, DialogModule } from "primeng/dialog";
import { PrimeTemplate } from "primeng/api";
import { ScreenNotificationVisibilityService } from "@platform-app/app/main/layout/screen-notification/screen-notification-visibility.service";
import { ScreenWidthService } from "@platform-app/app/main/shared/service/screen-width.service";

@Component({
  selector: "app-screen-notification",
  standalone: true,
  imports: [DialogModule, PrimeTemplate],
  templateUrl: "./screen-notification.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenNotificationComponent implements OnDestroy {
  private readonly renderer = inject(Renderer2);
  private readonly screenNotificationVisibilityService = inject(
    ScreenNotificationVisibilityService,
  );
  private readonly screenWidthService = inject(ScreenWidthService);

  dialog = viewChild(Dialog);

  isDialogOpened = computed(
    () =>
      this.isMobile() &&
      this.screenNotificationVisibilityService.shouldShowDialog(),
  );

  private isMobile = this.screenWidthService.isMobile;

  constructor() {
    effect(() => {
      if (this.isDialogOpened() && this.dialog()) {
        this.applyScrollLock();
        this.dialog()!.maximized = true;
      } else {
        this.removeScrollLock();
      }
    });
  }

  private applyScrollLock(): void {
    this.renderer.addClass(document.body, "overflow-hidden");
    this.renderer.addClass(document.documentElement, "overflow-hidden");
  }

  private removeScrollLock(): void {
    this.renderer.removeClass(document.body, "overflow-hidden");
    this.renderer.removeClass(document.documentElement, "overflow-hidden");
  }

  ngOnDestroy(): void {
    this.removeScrollLock();
  }
}
