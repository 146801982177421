import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { Permissions } from "@platform-app/app/core/api/models";
import { OnboardingWizardState } from "@platform-app/app/onboarding-wizard/shared/onboarding-wizard.state";
import { GetOrganizationOnboardingWizardStatus } from "@platform-app/app/onboarding-wizard/shared/actions";
import { Observable, map } from "rxjs";

const ONBOARDING_PERMISSIONS = [
  Permissions.SchoolOnboardingView,
  Permissions.ClinicOnboardingView,
];

export const onboardingWizardCompletedGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  const user = store.selectSnapshot(AuthState.user);

  if (user?.permissions.some((p) => ONBOARDING_PERMISSIONS.includes(p))) {
    return checkWizardCompletion(store).pipe(
      map((completed) =>
        completed ? true : router.createUrlTree(["/onboarding"]),
      ),
    );
  }

  return true;
};

export const onboardingWizardAccessibleGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  const user = store.selectSnapshot(AuthState.user);

  if (user?.permissions.some((p) => ONBOARDING_PERMISSIONS.includes(p))) {
    return checkWizardCompletion(store).pipe(
      map((completed) =>
        completed ? router.createUrlTree(["/dashboard"]) : true,
      ),
    );
  }

  return router.createUrlTree([`/dashboard`]);
};

function checkWizardCompletion(store: Store): Observable<boolean> {
  const steps = store.selectSnapshot(OnboardingWizardState.steps);

  if (steps.length) {
    return store.select(OnboardingWizardState.completed);
  }

  return store
    .dispatch(new GetOrganizationOnboardingWizardStatus())
    .pipe(map(() => store.selectSnapshot(OnboardingWizardState.completed)));
}
