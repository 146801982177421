import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { GetOrganizationOnboardingWizardStatus } from "@platform-app/app/onboarding-wizard/shared/actions";
import {
  WizardStep,
  WizardStepModel,
} from "@platform-app/app/onboarding-wizard/shared/models";
import { OnboardingWizardState } from "@platform-app/app/onboarding-wizard/shared/onboarding-wizard.state";
import { map, Observable, of } from "rxjs";

export const onboardingWizardResolver: ResolveFn<Observable<void | null>> = (
  route: ActivatedRouteSnapshot,
): Observable<void | null> => {
  const router = inject(Router);
  const store = inject(Store);

  const redirectUrl = ["/dashboard"];
  const attemptedStep = route.children.find((c) => !!c.data)?.data["step"];

  return fetchWizardSteps(store).pipe(
    map((steps) => {
      const attemptedStepModel = steps.find((s) => s.step === attemptedStep);

      // If attempted step doesn't exist for current User -> redirect to dashboard
      if (!attemptedStepModel) {
        router.navigate(redirectUrl);
        return;
      }

      // If attempted step is completed -> do nothing
      if (attemptedStepModel.isCompleted) {
        return;
      }

      const completedStepModel = steps
        .slice()
        .reverse()
        .find((s) => s.isCompleted);

      // If there are none completed steps -> redirect to the initial onboarding step
      if (!completedStepModel) {
        if (attemptedStep !== WizardStep.Welcome) {
          router.navigate(["/onboarding"]);
        }
        return;
      }

      const nextIncompleteStepModel = steps.at(
        steps.indexOf(completedStepModel) + 1,
      );

      if (
        !nextIncompleteStepModel ||
        nextIncompleteStepModel.step === attemptedStep
      ) {
        return;
      }

      router.navigate(["onboarding", nextIncompleteStepModel.route]);
    }),
  );
};

function fetchWizardSteps(store: Store): Observable<WizardStepModel[]> {
  const steps = store.selectSnapshot(OnboardingWizardState.steps);

  if (steps.length) {
    return of(steps);
  }

  return store
    .dispatch(new GetOrganizationOnboardingWizardStatus())
    .pipe(map(() => store.selectSnapshot(OnboardingWizardState.steps)));
}
