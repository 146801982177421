import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { GetCurrentAffiliationAgreement } from "@platform-app/app/agreement-builder/agreement-builder.actions";
import { catchError, EMPTY, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export const agreementBuilderResolver: ResolveFn<Observable<void | null>> = (
  route: ActivatedRouteSnapshot,
): Observable<void | null> => {
  const router = inject(Router);
  const store = inject(Store);
  const agreementId = route.queryParamMap.get("agreementId");

  if (agreementId) {
    return store
      .dispatch(new GetCurrentAffiliationAgreement({ agreementId }))
      .pipe(
        map(() => null),
        catchError(() => {
          router.navigate(["/partners"]);
          return EMPTY;
        }),
      );
  }

  return of(null);
};
